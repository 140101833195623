import { FC, useState } from "react"
import useTranslation from "next-translate/useTranslation"
import styled from "styled-components"
import Trans from "next-translate/Trans"
import { useBoolean } from "usehooks-ts"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import dynamic from "next/dynamic"
import LoginViaFacebookButton from "../Login/LoginViaFacebookButton"
import LoginViaGoogleButton from "../Login/LoginViaGoogleButton"
import { GoogleOAuthProvider } from "@react-oauth/google"
import appConfig from "../../app-config"
import OrText from "../Layout/OrText"
import {
  MobileNavigation,
  useMobileNavigation,
} from "@hornet-web-react/core/components/Layout"
import MobileFirstLayout from "../Layout/MobileFirstLayout"
import GuestVisitorContentWrapper from "../Layout/GuestVisitorContentWrapper"

type PageLoginProps = {
  redirectTo: string | null
}

const LoginViaEmailScreen = dynamic(
  () => import("../Login/LoginViaEmailScreen")
)
const PrivacyPolicyModal = dynamic(() => import("../Modals/PrivacyPolicyModal"))
const TermsOfServiceModal = dynamic(
  () => import("../Modals/TermsOfServiceModal")
)

const TopParagraph = styled.div`
  margin: ${({ theme }) => theme.spacing.regular} 0;
`

const Line1 = styled.p`
  ${({ theme }) => theme.font.regular.title3};
  color: ${({ theme }) => theme.color.text.tertiary};
  margin: 0;

  em {
    color: ${({ theme }) => theme.color.text.primary};
    font-style: normal;
  }
`

const Line2 = styled.h1`
  ${({ theme }) => theme.font.regular.title3};
  color: ${({ theme }) => theme.color.text.primary};
  margin: 0;
`

const ButtonGroup = styled.div`
  margin: ${({ theme }) => theme.spacing.regular} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
`

const SocialButtons = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  button:first-child {
    margin-right: ${({ theme }) => theme.spacing.less};
  }
`

const BottomLine = styled.p`
  ${({ theme }) => theme.font.regular.caption1};
  color: ${({ theme }) => theme.color.text.tertiary};
  margin: ${({ theme }) => theme.spacing.double} 0 0 0;
`

const i18nKey = "hornet:components.pages.page_login"

const PageLogin: FC<PageLoginProps> = ({ redirectTo }) => {
  const { t } = useTranslation()
  const { createNavLinkButton } = useMobileNavigation()

  const [isGoogleButtonError, setIsGoogleButtonError] = useState(false)

  const {
    value: isEmail,
    setTrue: setUseEmail,
    setFalse: backToSignpost,
  } = useBoolean(false)

  const {
    value: isPrivacyPolicyModalVisible,
    setTrue: showPrivacyPolicyModal,
    setFalse: hidePrivacyPolicyModal,
  } = useBoolean(false)

  const {
    value: isTermsOfServiceModalVisible,
    setTrue: showTermsOfServiceModal,
    setFalse: hideTermsOfServiceModal,
  } = useBoolean(false)

  return (
    <GoogleOAuthProvider
      clientId={appConfig.auth.google.clientId}
      onScriptLoadError={() => setIsGoogleButtonError(true)}
    >
      <MobileFirstLayout leftPanel={undefined} hasPadding={false}>
        <GuestVisitorContentWrapper>
          {!isEmail && (
            <>
              <MobileNavigation
                title={""}
                leftAction={createNavLinkButton(
                  {
                    label: t(`${i18nKey}.buttons.back_button`),
                    hasChevron: true,
                    href: "/",
                  },
                  "left"
                )}
              />

              <TopParagraph>
                <Line1>{t(`${i18nKey}.text.line1`)}</Line1>
                <Line2>{t(`${i18nKey}.text.line2`)}</Line2>
              </TopParagraph>

              <ButtonGroup>
                <TextButton onClick={setUseEmail} primary={true}>
                  {t(`${i18nKey}.buttons.email_login`)}
                </TextButton>

                <OrText />

                <SocialButtons>
                  <LoginViaFacebookButton redirectTo={redirectTo} />

                  <LoginViaGoogleButton
                    isDisabled={isGoogleButtonError}
                    redirectTo={redirectTo}
                  />
                </SocialButtons>
              </ButtonGroup>

              <BottomLine>
                <Trans
                  i18nKey={`${i18nKey}.text.bottom_line`}
                  components={[
                    /* eslint-disable-next-line react/jsx-key */
                    <a
                      href={"#toc"}
                      onClick={(event) => {
                        event.preventDefault()
                        showTermsOfServiceModal()
                      }}
                    />,
                    /* eslint-disable-next-line react/jsx-key */
                    <a
                      href={"#privacypolicy"}
                      onClick={(event) => {
                        event.preventDefault()
                        showPrivacyPolicyModal()
                      }}
                    />,
                  ]}
                />
              </BottomLine>
            </>
          )}

          {isEmail && (
            <LoginViaEmailScreen
              redirectTo={redirectTo}
              onBack={backToSignpost}
            />
          )}

          {isPrivacyPolicyModalVisible && (
            <PrivacyPolicyModal
              isOpen={isPrivacyPolicyModalVisible}
              onClose={hidePrivacyPolicyModal}
            />
          )}

          {isTermsOfServiceModalVisible && (
            <TermsOfServiceModal
              isOpen={isTermsOfServiceModalVisible}
              onClose={hideTermsOfServiceModal}
            />
          )}
        </GuestVisitorContentWrapper>
      </MobileFirstLayout>
    </GoogleOAuthProvider>
  )
}

export default PageLogin
