import Layout from "../components/Layout"
import appConfig from "../app-config"
import useTranslation from "next-translate/useTranslation"
import PageLogin from "../components/Pages/PageLogin"
import { getRt } from "../utils/od"
import { useEffect } from "react"
import { useHornetSessionUserLogin } from "../hooks/use-hornet-session-user-login"
import { useNavigation } from "../hooks/use-navigation"
import { useRouter } from "next/router"
import { getValidRedirectTo } from "@hornet-web-react/login/utils/get-valid-redirect-to"
import { PartialSessionState } from "@hornet-web-react/core/types/session"
import { getPublicServerSideProps } from "../utils/get-session-props-from-server-side-props"

export default function Page({ redirectTo }) {
  const { t } = useTranslation()
  const { navigateHome, routeForOnboarding } = useNavigation()
  const router = useRouter()

  // redirect to feed (/user) if logged in
  const { isLoggedIn } = useHornetSessionUserLogin()
  useEffect(() => {
    if (isLoggedIn && !redirectTo) {
      void navigateHome()
      return
    }

    void router.prefetch(routeForOnboarding)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, redirectTo, navigateHome, routeForOnboarding])

  return (
    <Layout
      showFooter={true}
      showTopNavBar={true}
      showBottomNavBar={false}
      hideTopNavBarMenu={true}
      title={t("hornet:pages.login.title")}
    >
      <PageLogin redirectTo={redirectTo} />
    </Layout>
  )
}

export const getServerSideProps = (context) => {
  // look for `redirect` in the query params
  // if present, clear out the current logged-in user
  const validRedirectTo = getValidRedirectTo(
    context.query.redirect,
    appConfig.appUrl
  )

  const propsOverride: PartialSessionState = {}

  if (validRedirectTo) {
    propsOverride["user"] = { isAuthenticated: false, currentUser: null }
  }

  const serverSideProps = getPublicServerSideProps(context, propsOverride)

  if ("props" in serverSideProps) {
    return {
      props: {
        ...serverSideProps.props,
        redirectTo: validRedirectTo,
        rt: getRt(process.env["REQ1"], process.env["REQ2"], appConfig.version),
      },
    }
  }

  return serverSideProps
}
